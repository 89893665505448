import React, { useEffect, useState } from 'react'
import { Grid, InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core'
import LabelWithIcon from '../Common/LabelWithIcon'
import { useTranslation } from 'react-i18next'


const useStyles = makeStyles({
  cmpWrapper: {
    backgroundColor: '#fff',
    width: '100%',
    '& legend': {
      textAlign: 'left',
      marginBottom: '10px',
      display: 'flex',
      fontSize: '14px',
      // color: '#d0d0d0',
      color: '#3d3d3d',
      '& svg': {
        fontSize: '15px',
        color: '#222',
        marginLeft: '6px',
      }
    }
  },
  inputStyle:{
    marginTop: "10px",
    marginBottom: "10px",
    "& .MuiInputBase-root.Mui-disabled":{
        background:"hsl(0, 0%, 95%)"
    }
  }
})

type inputDataType = {
  value: string,
  label: string,
}

const enumFormAction = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  VIEW: 'VIEW',
}

type SiibCommissionRateProps = {
  insuranceInputs: any,
  addSiibCommission: (label: string, value: number) => void,
  submitEvent: boolean,
  formType: string,
  siibComissionRate: any
  isArabicLanguage?: boolean
}


const CustomInput = (props: any) => {
  const [inputData, setInputData] = useState<number | null>(null)
  const [showError, setShowError] = useState(false);
  const [count, setCount] = useState(0)
  const {t}  = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    setCount(count + 1)
    if (count) {
      inputData === null ? setShowError(true) : setShowError(false)
    }
    props.siibPercentageData && setShowError(false)
  }, [props.submitEvent])

  return (
    <>
    <LabelWithIcon label={`${props.data?.label?.toLowerCase() && t(props.data?.label?.toLowerCase().replace(/ /g, '_').replace(/&/g, 'and')) } ${t("insurance")}`} />
      <TextField
        className={classes.inputStyle}
        type="number"
        id="outlined-basic"
        label=""
        variant="outlined"
        fullWidth
        name={props.data?.label}
        value={props.siibPercentageData}
        // value={props.data.value}
        //   onBlur={(e:any) => props.insuranceUpdate(props.data?.label, e.target.value)}
        onChange={(e: any) => {
          const val = e.target.value.replace(/\D/g, "");
          if (val <= 100) {
            setInputData(val)
            val && setShowError(false);
            props.insuranceUpdate(props.data?.label, val)
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">%</InputAdornment>
          ),
          readOnly: props.formType === enumFormAction.EDIT || props.formType === enumFormAction.ADD ? false : true,
          disabled: props.formType === enumFormAction.EDIT || props.formType === enumFormAction.ADD ? false : true,
        }}
        // value={formik.values.iban}
        // onChange={formik.handleChange}
        error={(inputData !== null && inputData > 100 ? true : false) || showError}
        //   helperText={formik.touched.iban && formik.errors.iban}
        required
      />
      {(props.siibPercentageData === "") && (
        <Typography color="error">
          {`${t('please_enter_valid')} ${props.data?.label?.toLowerCase() && t(props.data?.label?.toLowerCase().replace(/ /g, '_'))} ${t("insurance")}`}
        </Typography>
      )}

      {(inputData !== null && inputData > 100 ? true : false) || showError&& (
        <Typography color="error">
          {`${t('please_enter_valid')} ${props.data?.label?.toLowerCase() && t(props.data?.label?.toLowerCase().replace(/ /g, '_')) } ${t("insurance")}`}
        </Typography>
      )}
    </>
  )
}

const SiibCommissionRate:React.FC<SiibCommissionRateProps>  = (props: SiibCommissionRateProps) => {
  const classes = useStyles();
  const [allInsurancesData, setAllInsurancesData] = useState<any>(null)

  useEffect(() => {
    setAllInsurancesData(props.insuranceInputs)
  }, [])


  const handleInsuranceUpdate = (label: string, value: number) => {
    if (value <= 100) {
      props?.addSiibCommission(label, value)
    }
  }
  return (
    <div className={classes.cmpWrapper}>
      <Grid container spacing={3}>
        {
          props?.insuranceInputs && props?.insuranceInputs?.types_of_insurance && props?.insuranceInputs?.types_of_insurance?.map((item: any) => (
            <Grid item xs={12} sm={4} key={item}>
              <CustomInput data={item} formType={props?.formType} isArabicLanguage={props.isArabicLanguage} siibPercentageData={props?.siibComissionRate[item?.label]} insuranceUpdate={handleInsuranceUpdate} submitEvent={props?.submitEvent} />
            </Grid>
          ))
        }
      </Grid>
    </div>
  )
}

export default SiibCommissionRate